import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import dynamic from 'next/dynamic';
import { H2 } from '../../../../components';

import css from './SectionSlider.module.css';

import background from './img/background.svg';
import heart from './img/heart.webp';
import logoWhite from './img/logoWhite.webp';
import profileNicole from './img/profileNicole.jpg';
import profileSarah from './img/profileSarah.jpg';
import profileKate from './img/profileKate.jpg';
import profileAmiya from './img/profileAmiya.jpg';
import profileSonia from './img/profileSonia.jpg';
import avatarNicole from './img/avatarNicole.jpg';
import avatarSarah from './img/avatarSarah.jpg';
import avatarKate from './img/avatarKate.jpg';
import avatarAmiya from './img/avatarAmiya.jpg';
import avatarSonia from './img/avatarSonia.jpg';


const sliderContent = [
    {
        profile: profileNicole,
        avatar: avatarNicole,
        text: 'I had the most amazing experience getting my treatment through Blumi! My skin looks and feels incredible after the treatment. I\'ve already booked my next session through this!',
        displayName: 'Nicole',
        location: 'London',
        position: 'Lip Filler',
    },
    {
        profile: profileSarah,
        avatar: avatarSarah,
        text: 'Used Blumi for a much-needed hair makeover, and wow, they did not disappoint! The stylist knew exactly what I wanted and nailed it. My hair feels so healthy and vibrant now. The friendly atmosphere is just the cherry on top!',
        displayName: 'Sarah',
        location: 'London',
        position: 'Lip Filler',
    },
    {
        profile: profileKate,
        avatar: avatarKate,
        text: 'Got my makeup done through Blumi for a special event, and it was phenomenal! The artist listened to what I wanted and delivered exactly that. The products used were top-notch and lasted all night. I\'ll definitely be booking through Blumi again!',
        displayName: 'Kate',
        location: 'Manchester',
        position: 'Eyelash Extensions',
    },
    {
        profile: profileAmiya,
        avatar: avatarAmiya,
        text: 'I\'ve always wanted to try out some cosmetic treatments but couldn\'t justify the cost. This platform made it affordable and got to try out a new clinic. Love the results, totally recommend!',
        displayName: 'Amiya',
        location: 'Manchester',
        position: 'Eyelash Extensions',
    },
    {
        profile: profileSonia,
        avatar: avatarSonia,
        text: 'Such a cool experience! This was my first time booking as a model for botox and the results are fab. The practitioners were super nice and professional. I loved meeting the other models too!',
        displayName: 'Sonia',
        location: 'Liverpool',
        position: 'Tattoo Removal',
    }
]

const Swiper =
    typeof window !== 'undefined'
        ? require('swiper/react').Swiper
        : dynamic(() => import('swiper/react').then(mod => mod.Swiper), {
            ssr: false,
        });

const SwiperSlide =
    typeof window !== 'undefined'
        ? require('swiper/react').SwiperSlide
        : dynamic(() => import('swiper/react').then(mod => mod.SwiperSlide), {
            ssr: false,
        });

const EffectCoverflow =
    typeof window !== 'undefined'
        ? require('swiper/modules').EffectCoverflow
        : dynamic(() => import('swiper/modules').then(mod => mod.EffectCoverflow), {
            ssr: false,
        });

const SectionSlider = props => {
    const [profileImage, setProfileImage] = useState(sliderContent[0].profile);
    const [fade, setFade] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            require('swiper/css');
            require('swiper/css/bundle');
            require('swiper/css/effect-coverflow');
        }
    }, []);

    const swiperRef = useRef(null);

    const handleSlideChange = (swiper) => {
        setFade(true);
        setTimeout(() => {
            setProfileImage(sliderContent[swiper.realIndex].profile);
            setFade(false);
        }, 300);
    };

    const slidesToShow = typeof window !== 'undefined' && window.innerWidth < 768 ? 1 : 5;

    return (
        <div className={css.root}>
            <img className={css.backgroundImage} src={background} />
            <span className={css.tag}>
                <FormattedMessage id="SectionSlider.tag" />
            </span>
            <H2 className={css.title}>
                <FormattedMessage
                    id="SectionSlider.titlePart1"
                />
                <br />
                <span className={css.customGradient}>
                    <FormattedMessage
                        id="SectionSlider.titlePart2"
                    />
                </span>
                <FormattedMessage
                    id="SectionSlider.titlePart3"
                />
                <img src={heart} />
            </H2>

            <div className={css.sliderContainer}>
                <div className={css.phoneWrapper}>
                    <img className={`${css.logoWhite} ${fade ? css.fade : ''}`} src={logoWhite} alt="logoWhite" />
                    <img className={`${css.profileImage} ${fade ? css.fade : ''}`} src={profileImage} alt="profile" />
                </div>
                <Swiper
                    className={css.swiper}
                    loop={true}
                    centeredSlides={true}
                    grabCursor={true}
                    slidesPerView={slidesToShow}
                    onSlideChange={handleSlideChange}
                    ref={swiperRef}
                    modules={[EffectCoverflow]}
                    effect={'coverflow'}
                    coverflowEffect={{
                        rotate: 20,
                        stretch: 0,
                        depth: -25,
                        modifier: 1,
                        slideShadows: false,
                    }}
                >
                    {sliderContent.map((profile, index) =>
                        <SwiperSlide
                            key={index}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div className={css.phoneContent}>
                                <div className={css.messaging}>
                                    <span className={css.text}>{profile.text}</span>
                                    <div className={css.profileContainer}>
                                        <img src={profile.avatar} />
                                        <div className={css.profileInfo}>
                                            <span>{profile.displayName}</span>
                                            {/* <br />
                                            <span>{profile.city}</span>
                                            <br />
                                            <span>{profile.position}</span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper >
            </div>
        </div >
    )
};

export default SectionSlider;