import React from 'react';
import { FormattedMessage, } from '../../../../util/reactIntl';
import { H2 } from '../../../../components';

import css from './SectionMatched.module.css';


import FindYourTreatmentImage from './img/find-your-treatment.webp';
import BookWithClinicImage from './img/book-with-clinic.webp';
// import FeelGoodImage from './img/feel-good.webp';
import FeelGoodImage from './img/lady.png';
import SunIcon from './img/sun.svg';

const SectionMatched = props => {
    return (
        <div className={css.root}>
            <span className={css.tag}>
                <FormattedMessage id="SectionMatched.tag" />
            </span>
            <H2 className={css.title}>
                <FormattedMessage
                    id="SectionMatched.titlePart1"
                />
                <br />
                <span className={css.customGradient}>
                    <FormattedMessage
                        id="SectionMatched.titlePart2"
                    />
                </span>
            </H2>
            <p className={css.description}>
                <FormattedMessage
                    id="SectionMatched.description"
                    values={{ lineBreak: <br /> }}
                />
            </p>
            <div className={css.imagesContainer}>
                <div className={css.image1Container}>
                    <img className={css.image1} src={FindYourTreatmentImage} alt="Find your treatment" />
                    <span className={css.text1}>
                        <FormattedMessage id="SectionMatched.findYourTreatment" />
                    </span>
                </div>

                <div className={css.image2Container}>
                    <img className={css.image2} src={BookWithClinicImage} alt="Book with the clinic" />
                    <span className={css.text2}>
                        <FormattedMessage id="SectionMatched.bookWithTheClinic" />
                    </span>
                </div>

                <div className={css.image3Container}>
                    <img className={css.image3} src={FeelGoodImage} alt="Feel good for less" />

                    <span className={css.text3}>
                        <FormattedMessage id="SectionMatched.feelGoodForLess" />
                        <img className={css.sun} src={SunIcon} />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default SectionMatched;